import { Provider } from '@angular/core';
import { LogService, TOKENS as TOKENS_COMMON } from '@trustedshops/tswp-core-common';
import { TOKENS as TOKENS_ANALYTICS } from '@trustedshops/tswp-core-analytics';
import { TrackingServiceImpl } from '@trustedshops/tswp-core-analytics-implementation';

/**
 * Mock the TrackingService
 * TrackingService are not available for employees
 */
export const TrackingConfiguration: Provider[] = [
  {
    provide: TOKENS_ANALYTICS.TrackingService,
    useFactory: (logService: LogService) =>
      new TrackingServiceImpl(logService, []),
    deps: [TOKENS_COMMON.LogService]
  }
];

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableUiElements, allControls } from '@trustedshops/tswp-core-ui-elements';
import { environment } from '../environments/environment';
import { BrowserAppModule } from './browser.module';

if (environment.production) {
  enableProdMode();
}

const platform = platformBrowserDynamic();
platform.bootstrapModule(BrowserAppModule)
  .catch(err => console.error(err));

enableUiElements(
  {
    platform,
    heliosUri: environment.layout.resources.heliosUri,
    heliosIconFontUri:  environment.layout.resources.heliosIconUri,
    controlModules: allControls
  });

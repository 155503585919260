import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { PluginRegistrationInfo, PluginResolverService } from '@trustedshops/tswp-carrier-core';
import { PluginHubService } from '@trustedshops/tswp-api-plugins-client-ng';

@Injectable()
export class PluginResolverServiceImpl implements PluginResolverService {
  //#region Private Fields
  private readonly _pluginHubService: PluginHubService;
  //#endregion

  //#region Ctor
  public constructor(pluginHubService: PluginHubService) {
    this._pluginHubService = pluginHubService;
  }
  //#endregion

  //#region Public Methods
  public async resolve(targetUserGroup: string): Promise<PluginRegistrationInfo[]> {
    return await this._pluginHubService
      .listPlatformPlugins(targetUserGroup)
      .pipe(take(1))
      .toPromise();
  }
  //#endregion
}

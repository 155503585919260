<link rel="stylesheet" href="/helios.css" />
<ng-container *ngIf="(items | async) as items$">
  <ng-container *ngIf="(activeItems | async) as activeItems$">
    <div class="hls-tab-host">
      <div class="hls-tab-headers">
        <user-interaction
          *ngFor="let item of items$ | sort:'order'"
          [interaction]="item.userInteraction"
          class="hls-tab-header"
          [ngClass]="{ 'hls-tab-header--active': activeItems$.includes(item) }"
          [renderingOptions]="{
            linkActiveOptions: {exact: false},
            forcedActive: activeItems$.includes(item)
          }">
          <div
            class="tertiary-menu-item"
            [innerText]="item.label"></div>
        </user-interaction>
      </div>
      <div class="hls-tab-content">
        <ng-content></ng-content>
      </div>
    </div>
  </ng-container>
</ng-container>

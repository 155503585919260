import { OrganizationalContainer, OrganizationalContainerSource } from '@trustedshops/tswp-core-masterdata';
import { Injectable } from '@angular/core';

@Injectable()
export class DefaultOrganizationalContainerSource implements OrganizationalContainerSource<any> {
  //#region Properties
  public get type(): string {
    return 'null';
  }
  //#endregion

  //#region Ctor
  public constructor() { }
  //#endregion

  //#region Public Methods
  public async getContainers(): Promise<OrganizationalContainer<any>[]> {
    return null;
  }
  //#endregion
}

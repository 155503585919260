<div class="topbar">
  <div class="topbar-breadcrumbs">
    <div *ngIf="breadcrumbs | async as $breadcrumbs">
      <ng-container *ngFor="let breadcrumb of $breadcrumbs; let i = index">
        <span class="topbar-breadcrumbs-separator" *ngIf="i > 0">‣</span>
        <user-interaction [interaction]="breadcrumb.userInteraction" class="topbar-breadcrumbs-interaction" *ngIf="i < $breadcrumbs.length - 1">
          <span [innerText]="breadcrumb.label" ></span>
        </user-interaction>
        <strong [innerText]="breadcrumb.label" *ngIf="i === $breadcrumbs.length - 1"></strong>
      </ng-container>
    </div>
  </div>
  <nav class="topbar-meta_menu">
    <ul>
      <li *ngFor="let navigationItem of metaMenuItems | sort:'order'"
          class="topbar-meta_menu-item">
        <user-interaction [interaction]="navigationItem.userInteraction"
                          [matTooltip]="navigationItem.label"
                          matTooltipPosition="below"
                          *ngIf="!navigationItem.children?.length">
          <span class="topbar-meta_menu-item-icon">
            <span [ngClass]="navigationItem.icon"></span>
          </span>
        </user-interaction>
        <hls-menu align="right" *ngIf="navigationItem.children?.length">
          <hls-icon [icon]="navigationItem.icon"
                    slot="toggle"
                    [matTooltip]="navigationItem.label"
                    matTooltipPosition="below"></hls-icon>
          <div slot="entry" *ngFor="let childNavigationItem of navigationItem.children">
            <user-interaction [interaction]="childNavigationItem.userInteraction">
              <hls-icon [icon]="childNavigationItem.icon"></hls-icon>
              <span [innerText]="childNavigationItem.label"></span>
            </user-interaction>
          </div>
        </hls-menu>
      </li>
    </ul>
  </nav>
</div>

import { NavigationRegions } from '@trustedshops/tswp-carrier-employees-contracts';
import { AbstractPersistentEvent } from '@trustedshops/tswp-core-common';
import { NavigationRegionConfiguration } from '@trustedshops/tswp-core-ui';

export const tertiaryNavigationRegion: NavigationRegionConfiguration = {
  name: NavigationRegions.TertiaryMenu,
  animationDurations: {
    expand: 0,
    collapse: 0
  },
  initialState: {
    collapsed: new AbstractPersistentEvent(false)
  }
};
